import React, { useState } from "react";
import debounce from "lodash.debounce";

const InfoBox = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const toggleVisibility = debounce(() => {
    if (!isVisible && isHovering) {
      setIsVisible(true);
    } else if (isVisible && !isHovering) {
      setIsVisible(false);
    }
  }, 50);

  return (
    <div className="relative inline-block">
      <button
        className="bg-black rounded-full p-0.2 text-white hover:bg-gray-800"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onMouseMove={toggleVisibility}
      >
        <svg
          className="w-6 h-6 inline-block fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path fill="#fff" d="M11 7h2v2h-2zM11 11h2v6h-2z" />
          <circle cx="12" cy="12" r="1" fill="#fff" />
        </svg>
      </button>
      {isVisible && (
        <div className="fixed inset-0 z-10 overflow-auto bg-black bg-opacity-60 flex justify-center items-center">
          <div className="max-w-screen-sm w-full bg-white p-4 sm:p-8 rounded-lg">
            <div className="flex justify-end">
              <button
                onClick={() => setIsVisible(false)}
                className="text-gray-600 hover:text-gray-800 focus:outline-none"
              >
                <svg
                  className="w-6 h-6 inline-block fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path
                    fill="#666"
                    d="M7.41 6.58L12 11.17l4.59-4.59a.996.996 0 111.41 1.41L13.41 12l4.59 4.59a.996.996 0 11-1.41 1.41L12 13.41l-4.59 4.59a.996.996 0 11-1.41-1.41L10.59 12 6 7.41a.996.996 0 010-1.83z"
                  />
                </svg>
              </button>
            </div>
            <div className="text-sm sm:text-base">{text}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoBox;
