import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import OCPAbi from "./data/OCPAbi.json";
import BridgeAbi from "./data/BridgeAbi.json";
import InfoBox from "./InfoBox";
import metamaskLogo from "./images/metamask-logo.png";

function Bridge() {
  const [connected, setConnected] = useState(false);
  const [nfts, setNfts] = useState([]);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingApproval, setLoadingApproval] = useState(false);
  const [loadingBridge, setLoadingBridge] = useState(false);
  const [btcAddress, setBtcAddress] = useState("");
  const [bridgeFee, setBridgeFee] = useState(0);
  const [messageApproval, setMessageApproval] = useState(""); // Add state to hold message
  const [messageBridge, setMessageBridge] = useState(""); // Add state to hold message
  const [selectAll, setSelectAll] = useState(false);

  const buttonClasses =
    "m-4 sm:m-8 px-4 sm:px-6 py-2 sm:py-4 bg-black border border-white text-white rounded-md flex items-center justify-center gap-2 transition-all duration-200 hover:bg-gray-700 hover:border-gray-700 hover:shadow-lg";

  const boxClasses =
    "m-2 sm:m-4 p-4 sm:p-6 bg-black text-white border border-white rounded-md flex items-center justify-center";

  const modalBackgroundClasses = "fixed inset-0 bg-black opacity-80 z-40";

  const modalContentClasses =
    "fixed inset-0 z-50 flex justify-center items-start overflow-auto";

  const modalBoxClasses =
    "bg-white p-4 sm:p-8 rounded-lg max-w-screen-sm w-full";

  const contractAddress = "0xdb804A76474532D6f72d463b7BC8E4D47c64e171";
  const bridgeContractAddress = "0xF594856Cc9626a3e4D5504b31206cca18323E4c0";
  const ocpContract = new ethers.Contract(contractAddress, OCPAbi);

  useEffect(() => {
    async function fetchBridgeFee() {
      // Fetch the bridge fee from the Bridge Contract
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const bridgeContract = new ethers.Contract(
        bridgeContractAddress,
        BridgeAbi,
        provider
      );
      const punksSelected = selectedNfts.length;
      const fee = await bridgeContract.bridgeFee();
      const bridgeFee = ethers.utils.formatEther(
        fee.mul(punksSelected).toString()
      );
      setBridgeFee(bridgeFee);
    }

    fetchBridgeFee();
  }, [selectedNfts]);

  async function connectWallet() {
    try {
      setLoading(true);
      // Check if the user is already connected to a wallet
      if (window.ethereum && !connected) {
        // Request account access if needed
        await window.ethereum.request({ method: "eth_requestAccounts" });

        // Get the provider object and connect to the Ethereum network
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();

        // Update the state to indicate that the user is connected
        setConnected(true);

        // Get the address of the connected account
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        // Get the NFTs owned by the connected address
        const contractAddress = "0xdb804A76474532D6f72d463b7BC8E4D47c64e171";
        const nftContract = new ethers.Contract(
          contractAddress,
          OCPAbi,
          provider
        );
        const nftBalance = await nftContract.balanceOf(address);
        const tokenIds = [];
        for (let i = 0; i < nftBalance.toNumber(); i++) {
          const tokenId = await nftContract.tokenOfOwnerByIndex(address, i);
          tokenIds.push(tokenId.toNumber());
        }

        // Get the metadata for each NFT using the contract's tokenURI function
        const nftData = [];
        for (const tokenId of tokenIds) {
          const nftURI = await nftContract.tokenURI(tokenId);
          const { data: metadata } = await axios.get(nftURI);
          nftData.push({
            tokenId: tokenId,
            name: metadata.name,
            image: metadata.image,
          });
        }

        // Update the state with the NFT metadata
        setNfts(nftData);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function handleNftSelect(event, nft) {
    if (event.target.checked) {
      setSelectedNfts((selectedNfts) => [...selectedNfts, nft]);
    } else {
      setSelectedNfts((selectedNfts) =>
        selectedNfts.filter((selectedNft) => selectedNft !== nft)
      );
    }
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedNfts(nfts);
    } else {
      setSelectedNfts([]);
    }
  };

  async function handleApproveAll() {
    // Get user's wallet provider
    const provider = window.ethereum;

    // Check if provider is available
    if (!provider) {
      setMessageApproval(
        "Please install MetaMask or a similar wallet provider."
      );
      return;
    }

    // Request user's permission to access their wallet
    try {
      setLoadingApproval(true); // Start loading
      await provider.request({ method: "eth_requestAccounts" });
    } catch (error) {
      setMessageApproval("Failed to connect to wallet.");
      console.error("Failed to connect to wallet.", error);
      setLoadingApproval(false); // Stop loading
      return;
    }

    // Create a new ethers.js provider using the wallet provider
    const ethersProvider = new ethers.providers.Web3Provider(provider);

    // Get the user's current account address
    const signer = ethersProvider.getSigner();
    const userAddress = await signer.getAddress();

    // Get the OCP contract instance
    const ocpContractAddress = "0xdb804A76474532D6f72d463b7BC8E4D47c64e171";
    const bridgeContractAddress = "0xF594856Cc9626a3e4D5504b31206cca18323E4c0";
    const ocpContract = new ethers.Contract(ocpContractAddress, OCPAbi, signer);

    // Approve operator to manage all user's tokens
    try {
      setLoadingApproval(true); // Start loading
      const tx = await ocpContract.setApprovalForAll(
        bridgeContractAddress,
        true
      );
      await tx.wait();
      setMessageApproval("Approval successful!");
      console.log("Approval successful!");
      setLoadingApproval(false); // Stop loading
    } catch (error) {
      setMessageApproval("Approval failed.");
      console.error("Approval failed.", error);
      setLoadingApproval(false); // Stop loading
      return;
    }
  }

  function handleBtcAddressChange(event) {
    setBtcAddress(event.target.value);
  }

  async function bridgeNfts() {
    // Get user's wallet provider
    const provider = window.ethereum;

    // Check if provider is available
    if (!provider) {
      console.error("Please install MetaMask or a similar wallet provider.");
      return;
    }

    // Request user's permission to access their wallet
    try {
      await provider.request({ method: "eth_requestAccounts" });
    } catch (error) {
      console.error("Failed to connect to wallet.", error);
      return;
    }

    // Create a new ethers.js provider using the wallet provider
    const ethersProvider = new ethers.providers.Web3Provider(provider);

    // Get the user's current account address
    const signer = ethersProvider.getSigner();
    const userAddress = await signer.getAddress();

    // Get the bridge contract instance
    const bridgeContractAddress = "0xF594856Cc9626a3e4D5504b31206cca18323E4c0";
    const bridgeContract = new ethers.Contract(
      bridgeContractAddress,
      BridgeAbi,
      signer
    );

    // Calculate the total fee required to bridge all selected NFTs
    const totalFee = ethers.utils.parseEther(bridgeFee.toString());

    // Bridge the selected NFTs to BTC
    try {
      setLoadingBridge(true);
      const tx = await bridgeContract.bridge(
        selectedNfts.map((nft) => nft.tokenId),
        btcAddress,
        { value: totalFee }
      );
      await tx.wait();
      setMessageBridge("NFTs bridged successfully!");
      console.log("NFTs bridged successfully!");
      setLoadingBridge(false);
    } catch (error) {
      setMessageBridge("Failed to bridge NFTs to BTC.");
      console.error("Failed to bridge NFTs to BTC.", error);
      setLoadingBridge(false);
      return;
    }
  }

  return (
    <div className=" flex items-center justify-center">
      {!connected ? (
        <button className={buttonClasses} onClick={connectWallet}>
          <img
            src={metamaskLogo}
            alt="Metamask Logo"
            className="w-4 h-4 mr-2"
          />
          Connect Wallet
        </button>
      ) : (
        <>
          {showModal && (
            <>
              <div className={modalBackgroundClasses}></div>
              <div className={modalContentClasses}>
                {loading ? (
                  <div className="h-screen w-screen flex items-center justify-center text-white">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 00-8-8V0a12 12 0 0112 12h-4zM5.5 16.5l3.5-3.5 2.5 2.5 3.5-3.5 2.5 2.5L18.5 13"
                      ></path>
                    </svg>
                    <span>Loading NFTs...</span>
                  </div>
                ) : (
                  <div
                    onClick={(event) => event.stopPropagation()}
                    className={modalBoxClasses}
                  >
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-lg font-bold text-black">Bridge</h2>

                      <button
                        className="px-2 py-1 bg-white text-black rounded-md"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                    </div>
                    <div className="mb-4">
                      <h3 className="text-base font-medium mb-2">
                        Step 1: Select OnChainPunks NFTs to Bridge{" "}
                        <InfoBox text="Inscripted OnChainPunks are sent to the BTC address you specify at Step 3. If you want to receive your OnChainPunks to multiple Ordinal compatible Bitcoin Wallet Addresses you have to create separate Bridge TXs with different wallet addresses" />
                      </h3>
                      <p className="text-sm text-gray-600 mb-2">
                        Select one or multiple OnChainPunks that you want to
                        bridge to BTC
                      </p>
                      {nfts.length > 0 ? (
                        <div className="h-96 overflow-y-scroll">
                          <div className="flex justify-end mb-2">
                            <label className="flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-gray-600"
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                              <span className="ml-2 text-gray-700">
                                Select All
                              </span>
                            </label>
                          </div>
                          <ul className="grid grid-cols-2 gap-4">
                            {nfts.map((nft) => (
                              <li
                                key={nft.tokenId}
                                className="flex flex-col items-center p-4 bg-gray-100 rounded-md"
                              >
                                <label className="mb-2">
                                  <input
                                    type="checkbox"
                                    onChange={(event) =>
                                      handleNftSelect(event, nft)
                                    }
                                    checked={selectedNfts.includes(nft)}
                                  />
                                  <span className="ml-2">{nft.name}</span>
                                </label>
                                <img
                                  src={nft.image}
                                  alt={nft.name}
                                  className="h-32"
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <p className="text-red-600">
                          You don't have any OnChainPunks
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <h3 className="text-base font-medium mb-2">
                        Step 2: Approve OnChainPunks for Bridge{" "}
                        <InfoBox text="In order to bridge your OnChainPunks, you'll need to approve the OnChainPunks collection. This approval gives the Bridge contract permission to transfer your OnChainPunk NFTs. Please note that this approval is specific to the OnChainPunks collection. If you have approved already you don't have to approve again." />
                      </h3>
                      <p className="text-sm text-gray-600 mb-2">
                        Before bridging, you need to approve OnChainPunk NFTs
                        for the bridge contract
                      </p>
                      <button
                        className="mt-2 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded-md transition-colors"
                        onClick={handleApproveAll}
                      >
                        Approve All for Bridge
                        {loadingApproval ? (
                          <div className="flex items-center justify-center">
                            <svg
                              className="animate-spin h-5 w-5 mr-3 inline-block"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 00-8-8V0a12 12 0 0112 12h-4zM5.5 16.5l3.5-3.5 2.5 2.5 3.5-3.5 2.5 2.5L18.5 13"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <div
                            className={`text-center font-medium ${
                              messageApproval.includes("successful")
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {messageApproval}
                          </div>
                        )}
                      </button>
                    </div>

                    <div className="mb-4">
                      <h3 className="text-base font-medium mb-2">
                        Step 3: Enter BTC Address{" "}
                        <InfoBox text="Please ensure that you input an Ordinals compatible Bitcoin wallet address. This is the address where the inscriptions will be sent to once the bridging process is complete. If you are unsure of how to obtain an Ordinals compatible wallet address, feel free to ask for assistance on our Discord server." />
                      </h3>
                      <p className="text-sm text-gray-600 mb-2">
                        Enter Ordinals compatible BTC wallet address where you
                        want to receive the inscriptions
                      </p>
                      <div className="flex">
                        <input
                          type="text"
                          placeholder="Enter BTC Address"
                          className="w-full px-2 py-1 border rounded-md mr-2"
                          value={btcAddress}
                          onChange={handleBtcAddressChange}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <h3 className="text-base font-medium mb-2">
                        Step 4: Bridge to BTC{" "}
                        <InfoBox text="Once you bridge your NFTs, they will be locked on the Ethereum blockchain on our Bridge Smart Contract and you will receive equivalent tokens on the Bitcoin blockchain. This process can take up to 24 hours. Please ensure that the BTC address you provided is correct." />
                      </h3>
                      <p className="text-sm text-gray-600 mb-2">
                        Once you have completed all the steps press the Bridge
                        button to start the bridging process
                      </p>

                      <button
                        className="mt-2 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded-md transition-colors"
                        onClick={bridgeNfts}
                      >
                        Bridge
                        {loadingBridge ? (
                          <div className="flex items-center justify-center">
                            <svg
                              className="animate-spin h-5 w-5 mr-3 inline-block"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 00-8-8V0a12 12 0 0112 12h-4zM5.5 16.5l3.5-3.5 2.5 2.5 3.5-3.5 2.5 2.5L18.5 13"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <div
                            className={`text-center font-medium ${
                              messageBridge.includes("successful")
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {messageBridge}
                          </div>
                        )}
                      </button>
                    </div>
                    <div>
                      <p className="text-sm text-red-600 ml-2">
                        The bridge fee for {selectedNfts.length} NFT(s) is{" "}
                        {bridgeFee} ETH + Gas.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className={`${boxClasses} flex flex-col items-center`}>
            <h2 className="text-lg font-bold mb-4">Wallet Connected</h2>
            <button
              className="px-4 py-2 bg-black hover:bg-gray-800 text-white rounded-md border border-white transition-colors"
              onClick={() => setShowModal(true)}
            >
              Start Bridging
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Bridge;
