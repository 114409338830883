import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import OCPAbi from "./data/OCPAbi.json";
import BridgeAbi from "./data/BridgeAbi.json";

const ProgressBar = ({ progress }) => {
  const [placeholderWidth, setPlaceholderWidth] = useState(0);

  const progressWidth = progress + "%";
  const placeholderStyle = {
    backgroundColor: "#f7931a8a",
    height: "10px",
    borderRadius: "20px",
  };

  const progressStyle = {
    width: progressWidth,
    backgroundColor: "#f7931a",
    height: "10px",
    borderRadius: "20px",
    position: "relative",
  };

  const percentageStyle = {
    position: "absolute",
    top: "-30px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#FFF",
    textAlign: "center",
    width: "30px",
  };

  const placeholderRef = (node) => {
    if (node) {
      const width = 100 - progress;
      setPlaceholderWidth(width);
    }
  };

  const formattedProgress = progress.toFixed(2);

  return (
    <div style={placeholderStyle} ref={placeholderRef}>
      <div style={progressStyle}>
        <div style={percentageStyle}>{formattedProgress}%</div>
      </div>
    </div>
  );
};

const BridgeBox = () => {
  const [ethSupply, setEthSupply] = useState(0);
  const [btcSupply, setBtcSupply] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const ocpContract = new ethers.Contract(
      "0xdb804A76474532D6f72d463b7BC8E4D47c64e171",
      OCPAbi,
      provider
    );
    const bridgeContract = new ethers.Contract(
      "0xF594856Cc9626a3e4D5504b31206cca18323E4c0",
      BridgeAbi,
      provider
    );

    const getEthSupply = async () => {
      const totalSupply = await ocpContract.totalSupply();
      const totalBridged = await bridgeContract.totalBridged();
      const ethSupply = totalSupply.sub(totalBridged);
      setEthSupply(ethSupply.toNumber());
      setBtcSupply(totalBridged.toNumber());
      setProgress((totalBridged / 3332) * 100);
    };

    getEthSupply();
  }, []);

  return (
    <div className="flex flex-col items-center mb-8 max-w-screen-md w-full px-4">
      <div className="text-white text-center flex-1 flex-col justify-center mt-4">
        <p className="text-white text-lg md:text-2xl font-bold mb-2">
          OnChainPunks Bridged
        </p>
      </div>
      <div className="border rounded-lg p-4 flex flexwrap justify-center md:justify-between items-center w-full">
        <div className="text-white text-center flex-1">
          <h3 className="text-md md:text-lgfont-bold mb-2">Ethereum</h3>
          <p className="text-xl md:text-3xl font-bold mb-0">{ethSupply}</p>
        </div>
        <div className="border-l border-white h-8 md:h-16 m-4 hidden md:block"></div>
        <div className="text-white text-center flex-1">
          <h3 className="text-md md:text-lg font-bold mb-2">Bitcoin</h3>
          <p className="text-xl md:text-3xl font-bold mb-0">{btcSupply}</p>
        </div>
      </div>

      <div className="border rounded-lg p-4 mt-4 w-full flex justify-center items-center flex-col">
        <div className="text-white font-bold text-lg text-center mb-4 sm:text-xl">
          Bridged to Bitcoin
        </div>
        <div className="w-full">
          <ProgressBar progress={progress} />
        </div>
        <hr className="w-full m-4 border-gray-300" />
        <div className="text-white text-sm sm:text-base mb-2 text-xs">
          Bridge smart contract:{" "}
          <a
            href="https://etherscan.io/address/0xF594856Cc9626a3e4D5504b31206cca18323E4c0"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-400 transition-colors duration-300"
          >
            0xF594856Cc9626a3e4D5504b31206cca18323E4c0
          </a>
        </div>
      </div>

      <div className="border rounded-lg p-4 mt-4 w-full hover:bg-gray-700">
        <a
          href="https://docs.google.com/spreadsheets/d/16gpsLaKioogYRfwHosDNljLpWvRkqygB7RywpwEp_p0/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white font-bold text-lg hover:text-gray-400 transition-colors duration-300 text-center block w-full"
        >
          Proof of Inscriptions
        </a>
      </div>
    </div>
  );
};

export default BridgeBox;
