import React from "react";
import "./App.css";

// Import the OnChainPunks animation GIF

import Bridge from "./Bridge";
import BridgeBox from "./BridgeBox";
import OCPAnimation from "./images/OCPanimation.gif";

function App() {
  return (
    <div className="bg-[#070602] flex flex-col justify-center items-center min-h-screen">
      <div className="flex flex-col items-center mb-8 max-w-screen-md w-full px-4">
        <div className="flex flex-col justify-center items-center mb-4 w-full">
          <h1 className="text-white text-3xl md:text-6xl font-bold mb-4 text-center">
            OnChainPunks
          </h1>

          <img
            src={OCPAnimation}
            alt="OnChainPunks animation"
            className="w-16 md:w-32 h-16 md:h-32"
          />
        </div>
        <Bridge />
        <BridgeBox />

        <div>
          <div>
            <ul className="flex flex-row items-center justify-center mt-4">
              <li className="mx-2">
                <a
                  href="https://twitter.com/OnchainPunks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="filter invert w-6 md:w-8 h-6 md:h-8 hover:opacity-75 transition-opacity duration-300"
                    src="https://img.icons8.com/ios/50/000000/twitter.png"
                    alt="Twitter"
                  />
                </a>
              </li>
              <li className="mx-2">
                <a
                  href="https://etherscan.io/address/0xdb804a76474532d6f72d463b7bc8e4d47c64e171"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="filter invert w-6 md:w-8 h-6 md:h-8 hover:opacity-75 transition-opacity duration-300"
                    src="https://img.icons8.com/ios/50/000000/ethereum.png"
                    alt="Ethereum"
                  />
                </a>
              </li>
              <li className="mx-2">
                <a
                  href="https://opensea.io/collection/onchainpunks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="filter invert w-6 md:w-8 h-6 md:h-8 hover:opacity-75 transition-opacity duration-300"
                    src="https://img.icons8.com/ios/50/000000/opensea.png"
                    alt="OpenSea"
                  />
                </a>
              </li>
              <li className="mx-2">
                <a
                  href="https://discord.gg/yC6NdhaawB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="filter invert w-6 md:w-8 h-6 md:h-8 hover:opacity-75 transition-opacity duration-300"
                    src="https://img.icons8.com/ios/50/000000/discord-logo.png"
                    alt="Discord"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-white text-center mt-8">
        <p>&copy; 2023 OnChainPunks. All rights reserved.</p>
      </div>
    </div>
  );
}

export default App;
